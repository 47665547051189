import { customErrorResponse } from '../utility/custom-error-response'
import { request, setCustomOnResponseError, resetCustomOnResponseError } from './axios'
import {
  TNpiEligibilityMetaRow,
  TNpiEligibilityPayloadToArchive,
  TNpiEligibilityPayloadToCreate,
  TNpiEligibilityPayloadToUpdate,
  TNpiEligibilityRow,
} from '../type/npi-eligibility'

export const NpiEligibility = {
  getNpiEligibilities: async (ce_340b_id = '', include_terminated = false, search_key = '', npi_id = ''): Promise<TNpiEligibilityRow[]> => {
    const searchParam = search_key ? `&search_key=${search_key}` : ''
    const npiParam = npi_id ? `&npi_id=${npi_id}` : ''
    const isTerminatedParam = include_terminated ? `&include_terminated=${include_terminated}` : ''
    const { data } = await request({
      url: `/npi-eligibility/?ce_340b_id=${ce_340b_id}${searchParam}${npiParam}${isTerminatedParam}`,
    })
    return data
  },

  getNpiEligibilityByNpiId: async (npi_id = ''): Promise<TNpiEligibilityMetaRow[]> => {
    const npiParam = npi_id ? `search=${npi_id}` : ''
    const { data } = await request({
      url: `/npi-eligibility/meta/?${npiParam}`,
    })
    return data
  },

  createNpiEligibility: async (payloadToCreate: TNpiEligibilityPayloadToCreate) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/npi-eligibility/',
      data: {
        ...payloadToCreate,
      },
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  updateNpiEligibility: async (payloadToUpdate: TNpiEligibilityPayloadToUpdate) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'put',
      url: '/npi-eligibility/',
      data: {
        ...payloadToUpdate,
      },
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  archiveNpiEligibility: async (payloadToArchive: TNpiEligibilityPayloadToArchive) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'delete',
      url: `/npi-eligibility/?ce_340b_id=${payloadToArchive.ce_340b_id}&npi=${payloadToArchive.npi}&effective_start_date=${payloadToArchive.effective_start_date}`,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },
}
