import { ADManagement } from './ad-management'
import { App } from './app'
import { Dea } from './dea'
import { Synchronization } from './synchronization'
import { Distributor } from './distributor'
import { Draft } from './draft'
import { CoveredEntity } from './covered-entity'
import { Flag } from './flag'
import { Group } from './group'
import { InvoicesMappings } from './invoices-mappings'
import { Mapping } from './mapping'
import { Role } from './role'
import { Range } from './range'
import { Rate } from './rate'
import { Upload } from './upload'
import { ValidationRule } from './validation-rule'
import { CategoryClassification } from './category-classification'
import { SourceManagement } from './source-management'
import { PatientSource } from './patient-source'
import { NpiEligibility } from './npi-eligibility'

export const API = {
  ADManagement,
  App,
  Dea,
  Synchronization,
  Distributor,
  Draft,
  CoveredEntity,
  Flag,
  Group,
  InvoicesMappings,
  Mapping,
  Role,
  Range,
  Rate,
  Upload,
  ValidationRule,
  CategoryClassification,
  SourceManagement,
  PatientSource,
  NpiEligibility,
}
